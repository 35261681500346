import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadUserTrainingInformation,
  updateUserTrainingInfo,
} from "../../redux/actions/userActions";
import propTypes from "prop-types";
import UserTrainingInformationForm from "./UserTrainingInformationForm";
import Loading from "components/Shared/Loading";
import { toast } from "react-toastify";
import { IsNullOrEmptyOrUndefined } from "utils/validatorHelpers";

export function userTrainingInformationContainer({
  userTrainingInformation,
  loadUserTrainingInformation,
  loading,
  userId,
  updateUserTrainingInfo,
}) {
  const [userTrainingInfoState, setUserTrainingInfoState] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!IsNullOrEmptyOrUndefined(userId)) {
      loadUserTrainingInformation(userId).catch((error) => {
        console.log("Load user training information failed" + error);
      });
    }
  }, [userId]);

  useEffect(() => {
    setUserTrainingInfoState(userTrainingInformation);
  }, [userTrainingInformation]);

  function handleUserTrainingInfo(event) {
    const { name, value } = event.target;
    setUserTrainingInfoState((prevUserTrainingInfo) => ({
      ...prevUserTrainingInfo,
      [name]: value,
    }));
  }

  const handleUserTrainingInfoSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const userTrainingInformationToUpdate = {
      userId: userId,
      vdot: userTrainingInfoState.vdot,
      hrMax: userTrainingInfoState.hrMax,
      restingHeartRate: userTrainingInfoState.restingHeartRate,
      lactateThreshold: userTrainingInfoState.lactateThreshold,
      lactateThresholdHr: userTrainingInfoState.lactateThresholdHr,
    };
    updateUserTrainingInfo(userTrainingInformationToUpdate)
      .then(() => {
        toast.success(
          "Informacje treningowe użytkownika zostały zaktualizowane poprawnie!"
        );
        setErrors([]);
        setSaving(false);
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  };

  return (
    <div className="content">
      {loading ? (
        <Loading size="7"></Loading>
      ) : (
        <>
          <UserTrainingInformationForm
            onUserTrainingInfoChange={handleUserTrainingInfo}
            onUserTrainingInfoSave={handleUserTrainingInfoSave}
            userTrainingInformation={userTrainingInfoState}
          />
        </>
      )}
    </div>
  );
}

userTrainingInformationContainer.propTypes = {
  userTrainingInformation: propTypes.object.isRequired,
  loadUserTrainingInformation: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  updateUserTrainingInfo: propTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.selectedUser?.id || state.auth.userId,
    loading: state.apiCallsInProgress > 0,
    userTrainingInformation: state.user?.userTrainingInformation,
  };
}

const mapDispatchToProps = {
  loadUserTrainingInformation,
  updateUserTrainingInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userTrainingInformationContainer);
