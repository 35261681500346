import { useSelector } from "react-redux";
import { memo } from "react";
import Spinner from "components/common/Spinner";
import "./StravaTrainingDetailsStyle.css";
import { emptyPlaceholder } from "utils/dataFormater";

function StravaTrainingDetails() {
  const { data, isLoading, error } = useSelector((store) => store.strava);

  function translateActivityType(type) {
    switch (type.toLowerCase()) {
      case "run":
        return "Bieganie";
      case "swim":
        return "Pływanie";
      default:
        return type;
    }
  }

  function formatMovingTime(time) {
    let movingTime = "";
    const seconds = Math.round((time % 1) * 60);
    if (time < 60) {
      const minutes = Math.floor(time).toString().padStart(2, 0);
      movingTime = `00:${minutes}:${seconds.toString().padStart(2, 0)}`;
    } else {
      const hours = Math.floor(time / 60);
      const minutes = Math.floor(time % 60);

      movingTime = `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`;
    }

    return movingTime;
  }

  function calculatePace(distance, timeInMinutes) {
    // Sprawdzamy, czy dane wejściowe są poprawne
    if (distance <= 0 || timeInMinutes <= 0) {
      return "Dystans i czas muszą być większe od zera";
    }

    // Obliczamy średnie tempo w minutach na kilometr
    const pace = timeInMinutes / distance;

    // Rozbijamy wynik na minuty i sekundy
    const minutes = Math.floor(pace); // Część minutowa
    const seconds = Math.round((pace - minutes) * 60); // Część sekundowa

    // Obsługa sytuacji, gdzie sekundy == 60
    const formattedSeconds = seconds === 60 ? 0 : seconds;
    const formattedMinutes = seconds === 60 ? minutes + 1 : minutes;

    // Formatowanie wyniku
    return `${formattedMinutes}:${formattedSeconds.toString().padStart(2, "0")} min/km`;
  }

  if (isLoading) return <Spinner />;

  if (error) alert("Wystąpił nieoczekiwany błąd");

  if (!data || data?.length < 1)
    return (
      <div className="no-data">
        <i class="fas fa-sync text-orange icon-gap single-icon"></i>
        <strong className="margin-10px-left text-orange">
          Brak danych ze Stravy. Synchronizacja w toku...
        </strong>
      </div>
    );

  return (
    <div className="container" style={{ maxHeight: "600px" }}>
      {data?.map((training, index) => (
        <div class="trainingWrapper">
          <div class="header">Aktywność {index + 1}</div>
          <div className="team-single">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="team-single-text padding-50px-left sm-no-padding-left">
                  <div className="contact-info-section margin-40px-tb">
                    <ul className="list-style9">
                      <li>
                        <div className="list-row">
                          <div>
                            <i class="fas fa-running text-orange icon-gap"></i>
                            <strong className="margin-10px-left text-orange">
                              Typ aktywności:
                            </strong>
                          </div>
                          <div>
                            <p>{translateActivityType(training?.sport_type)}</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="list-row">
                          <div>
                            <i className="fas fa-stopwatch text-yellow icon-gap"></i>
                            <strong className="margin-10px-left text-yellow">
                              Czas aktywności:
                            </strong>
                          </div>
                          <div>
                            <p>{formatMovingTime(training?.moving_time)} </p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="list-row">
                          <div>
                            <i className="fas fa-map-marked-alt text-green icon-gap"></i>
                            <strong className="margin-10px-left text-green">
                              Dystans:
                            </strong>
                          </div>
                          <div>
                            <p>{training?.distance?.toFixed(2)} km</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="list-row">
                          <div>
                            <i class="fas fa-stopwatch-20 text-navy icon-gap"></i>
                            <strong className="margin-10px-left text-navy">
                              Średnie tempo:
                            </strong>
                          </div>
                          <div>
                            <p>
                              {calculatePace(
                                training?.distance,
                                training?.moving_time
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="list-row">
                          <div>
                            <i class="fas fa-heart text-red icon-gap"></i>
                            <strong className="margin-10px-left text-red">
                              Średnie tętno:
                            </strong>
                          </div>
                          <div>
                            <p>
                              {emptyPlaceholder(
                                training?.average_heartrate,
                                "u/m"
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="list-row">
                          <div>
                            <i class="fas fa-heartbeat text-dark-red icon-gap"></i>
                            <strong className="margin-10px-left text-dark-red">
                              Maksymalne tętno:
                            </strong>
                          </div>
                          <div>
                            <p>
                              {emptyPlaceholder(training?.max_heartrate, "u/m")}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="list-row">
                          <div>
                            <i class="fas fa-mountain text-sky icon-gap"></i>
                            <strong className="margin-10px-left text-sky">
                              Łączny wznios:
                            </strong>
                          </div>
                          <div>
                            <p>{training?.total_elevation_gain} metrów</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default memo(StravaTrainingDetails);
